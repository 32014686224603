
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { dateFormatter } from '../../utilities/Functions/formatting';
import MainTemplate from '../../atomic/templates/MainTemplate/MainTemplate.vue';
import Calendar from '../../atomic/organisms/Calendar/Calendar.vue';
import Card from '../../atomic/atoms/Card/Card.vue';
import Chart from '../../atomic/molecules/Chart/Chart.vue';
import NavTabs from '../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import Divider from '../../atomic/atoms/Divider/Divider.vue';
import InfoCard from '../../atomic/molecules/Info-Card/Info-Card.vue';
import TableCard from '../../atomic/molecules/Table-Card/Table-Card.vue';
import ChartCard from '../../atomic/molecules/Chart-Card/Chart-Card.vue';
import Notification from '../../atomic/molecules/Notification/Notification.vue';
import { ColumnDefinition, TableData } from '../../utilities/Types/table.types';
import { Tabs } from '../../utilities/Types/navigation.types';

export default defineComponent({
  components: {
    'arc-main-template': MainTemplate,
    'arc-calendar': Calendar,
    'arc-card': Card,
    'arc-chart': Chart,
    'arc-info-card': InfoCard,
    'arc-notification': Notification,
    'arc-table-card': TableCard,
    'arc-chart-card': ChartCard,
    'arc-divider': Divider,
    'arc-nav-tabs': NavTabs,
  },
  computed: {
    ...mapGetters([
      'announcements',
      'activeAnnouncement',
      'tips',
      'activeTip',
      'activeUpcomingCommunications',
      'allCommunicationReports',
      'userNotifications',
      'user',
      'access',
      'permissions',
    ]),
    lastSent(): string | null {
      return this.allCommunicationReports?.lastSentDate ? dateFormatter(this.allCommunicationReports?.lastSentDate) : null;
    },
    lastEnd(): string | null {
      return this.allCommunicationReports?.lastEndDate ? dateFormatter(this.allCommunicationReports?.lastEndDate) : null;
    },
    tabs(): Tabs {
      return [
        {
          title: 'DASHBOARD',
          activeTabFlag: '/dashboard',
        },
      ];
    },
    upcomingColumnDefinitions(): ColumnDefinition[] | null {
      // to-do: possibly pass through the endpoint too?
      return [
        {
          type: 'text',
          columnName: 'id',
          displayName: 'ID',
        },
        {
          type: 'text',
          columnName: 'brandText',
          displayName: 'Brand',
        },
        {
          type: 'text',
          columnName: 'description',
          displayName: 'Name',
        },
        {
          type: 'percent',
          columnName: 'approvalAmount',
          displayName: 'Approval',
        },
        {
          type: 'date',
          columnName: 'fromDate',
          displayName: 'Start Date',
        },
        {
          type: 'date',
          columnName: 'toDate',
          displayName: 'End Date',
        },
      ];
    },
    upcomingTableData(): TableData | null {
      return this.activeUpcomingCommunications ?? null;
    },
    averageApprovalChartData(): unknown | null {
      return this.allCommunicationReports?.averageApproval ?? null;
    },
    unresponsiveMallsColumnDefinitions(): ColumnDefinition[] | null {
      // to-do: possibly pass through the endpoint too?
      return [
        {
          type: 'text',
          columnName: 'mall',
          displayName: 'Mall',
        },
        {
          type: 'text',
          columnName: 'activeRetailers',
          displayName: 'Active Retailers',
          position: 'center',
        },
        {
          type: 'bar-chart',
          columnName: 'performance',
          displayName: 'Performance',
          position: 'center',
        },
        {
          type: 'percent',
          columnName: 'approved',
          displayName: 'Approved',
          position: 'center',
        },
      ];
    },
    unresponsiveMallsTableData(): TableData | null {
      return this.allCommunicationReports?.unresponsiveMalls?.malls ?? null;
    },
    unresponsiveChartData(): unknown | null {
      // to-do: add this to the reports object
      return {
        datasets: [
          {
            label: 'Average',
            data: [10, 40, 50, 20, 25, 15, 60],
            borderColor: '#707070',
            backgroundColor: '#707070',
            fill: true,
          },
        ],
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      };
    },
    unresponsiveChartOptions(): unknown | null {
      return {
        responsive: true,
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            display: false,
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      };
    },
  },
  async mounted(): Promise<void> {
    await this.fetchUserNotifications();
  },
  methods: {
    ...mapActions(['fetchUserNotifications']),
  },
});
