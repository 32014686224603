
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import '@fullcalendar/core/vdom'; // solve problem with Vite
import FullCalendar, { CalendarOptions, EventApi, DateSelectArg, EventClickArg } from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import Card from '../../atoms/Card/Card.vue';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import SingleSelectInputSuggestion from '../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import { Status } from '@/enums/Status';
import MultiSelectInput from '@/atomic/molecules/Multi-SelectInput/MultiSelectInput.vue';

export default defineComponent({
  components: {
    FullCalendar,
    'arc-card': Card,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-multi-select-input': MultiSelectInput,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin],
        headerToolbar: {
          left: '',
          center: 'title',
          right: 'prev,next today',
        },
        initialView: 'dayGridMonth',
        // editable: true, allows drag and drop
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        // select: this.handleDateSelect, allows clicking and dragging empty dates to create event
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        datesSet: this.handleMonthChange,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      } as CalendarOptions,
      currentEvents: [] as EventApi[],
      selectionInfo: {} as DateSelectArg,
      companyValue: null as IDropdownOption | null,
      allowShowOptions: false,
      showDraftsSelected: false,
      showExpiredSelected: false,
      showToggle: true,
      previousStart: null,
      previousEnd: null,
      tagValues: [] as IDropdownOption[],
    };
  },

  computed: {
    ...mapGetters([
      'modalOpen',
      'modalType',
      'modalComponent',
      'marketingCampaigns',
      'recruitmentCampaigns',
      'mallMemos',
      'storeMemos',
      'showDrafts',
      'showExpired',
      'companyOptions',
      'availableCompanySpecificTags',
      'loadingAllCommunications',
      'user',
      'access',
      'permissions',
      'allCommunications',
      'sidebarOpen',
      'refresh',
    ]),
    isLoading(): boolean {
      return this.loadingAllCommunications;
    },
    tagId(): string {
      if (this.tagValues.length < 4) {
        return 'tags';
      } else {
        return 'tags-second-row';
      }
    },
  },

  methods: {
    ...mapActions([
      'openModal',
      'closeModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'loadCompanies',
      'updateAllCommunications',
      'showUpdateDrafts',
      'hideUpdateDrafts',
      'showUpdateExpired',
      'hideUpdateExpired',
      'fetchCommunicationsByMonth',
      'clearSelectedMarketingCampaign',
      'clearSelectedRecruitmentCampaign',
      'clearSelectedMallMemo',
      'clearSelectedStoreMemo',
      'setCalendarLimits',
      'loadCompanyTags',
      'clearCompanyTags',
    ]),
    toggleDrafts() {
      if (!this.isLoading) {
        if (this.showDraftsSelected) {
          this.hideUpdateDrafts();
          this.showDraftsSelected = false;
        } else {
          this.showUpdateDrafts();
          this.showDraftsSelected = true;
        }
      }
      this.showToggle = false;
      setTimeout(() => {
        this.showToggle = true;
      }, 0);
    },
    toggleExpired() {
      if (!this.isLoading) {
        if (this.showExpiredSelected) {
          this.hideUpdateExpired();
          this.showExpiredSelected = false;
        } else {
          this.showUpdateExpired();
          this.showExpiredSelected = true;
        }
      }
      this.showToggle = false;
      setTimeout(() => {
        this.showToggle = true;
      }, 0);
    },
    async refreshData(payload: any, fromToggleOrCompany = false) {
      let data = await this.fetchCommunicationsByMonth({ firstDay: payload.startStr.slice(0, 10), lastDay: payload.endStr.slice(0, 10) });
      this.setCalendarLimits({ firstDay: payload.startStr.slice(0, 10), lastDay: payload.endStr.slice(0, 10) });
      if (data && data?.length > 0) {
        if (!this.showDrafts) {
          data = data.filter((communication: any) => communication.statusId !== Status.Draft && communication.statusId !== Status.Declined);
        }
        if (!this.showExpired) {
          data = data.filter(
            (communication: any) => communication.statusId !== Status.Expired && communication.statusId !== Status['Manually Expired'],
          );
        }
        if (this.companyValue && this.permissions?.superAdminAccess) {
          data = data.filter((communication: any) => communication.companyId === this.companyValue?.value);
        }
        if (this.tagValues.length > 0) {
          const tagIds = this.tagValues.map((tag: any) => tag.value);
          data = data.filter((communication: any) => communication.tags.some((tag: any) => tagIds.includes(tag.tagId)));
        }
        // data.push({
        //   title: 'test',
        //   startRecur: '2024-04-01',
        //   endRecur: '2024-04-18',
        //   daysOfWeek: [1, 2, 3, 4, 5],
        // });
        // data.push({
        //   title: 'my recurring event',
        //   rrule: {
        //     freq: 'weekly',
        //     byweekday: ['sa', 'su'],
        //     dtstart: '2024-03-01',
        //     until: '2025-03-18',
        //   },
        // });
        // data.push({
        //   title: 'my yearly event',
        //   rrule: {
        //     freq: 'yearly',
        //     dtstart: '2024-04-01',
        //   },
        // });
        // data.push({
        //   title: 'my 3rd friday of the month event',
        //   rrule: {
        //     freq: 'monthly',
        //     byweekday: ['fr'],
        //     bysetpos: 3,
        //     dtstart: '2024-04-01',
        //   }
        // });
        if (!this.previousStart && !this.previousEnd) {
          this.updateEvents(data);
        }
        if (this.previousStart && this.previousEnd) {
          if (this.previousStart !== payload.startStr || this.previousEnd !== payload.endStr) {
            this.updateEvents(data);
          }
        }
        if (fromToggleOrCompany) {
          this.updateEvents(data);
        }
      }
      this.previousStart = payload.startStr;
      this.previousEnd = payload.endStr;
    },
    async handleMonthChange(payload: any) {
      this.refreshData(payload);
    },
    handleDateSelect(selectInfo: DateSelectArg) {
      this.clearSelectedRecruitmentCampaign();
      this.clearSelectedMarketingCampaign();
      this.clearSelectedMallMemo();
      this.clearSelectedStoreMemo();
      this.updateModalType('select-communications');
      this.updateModalComponent('select');
      this.updateModalData(selectInfo);
      this.openModal();
      this.selectionInfo = selectInfo;
      let calendarApi = selectInfo.view.calendar;
      calendarApi.unselect();
      // to be used later
      // if (title) {
      //   calendarApi.addEvent({
      //     id: createEventId(),
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay,
      //   });
      // }
    },
    handleEventClick(clickInfo: EventClickArg) {
      let data = clickInfo.event._def.extendedProps;
      this.updateModalType('large');
      this.updateModalComponent(clickInfo.event._def.extendedProps.commType);
      this.updateModalData({ campaignId: data.communicationId, row: data });
      this.openModal();

      // to be used later
      // if (
      //   confirm(
      //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
      //   )
      // ) {
      //   clickInfo.event.remove();
      // }
    },
    handleEvents(events: EventApi[]) {
      this.currentEvents = events;
    },
    updateEvents(data: any) {
      this.calendarOptions.events = data;
    },
    updateCompany(value: IDropdownOption) {
      this.companyValue = value;
      this.loadCompanyTags({ companyId: value.value });
      this.refreshData({ startStr: this.previousStart, endStr: this.previousEnd }, true);
    },
    clearCompany(value: any) {
      if (value === '') {
        this.companyValue = null;
        this.clearCompanyTags();
        this.tagValues = [];
        this.refreshData({ startStr: this.previousStart, endStr: this.previousEnd }, true);
      }
    },
    updateTags(value: any): void {
      this.tagValues = value;
      this.refreshData({ startStr: this.previousStart, endStr: this.previousEnd }, true);
    },
  },
  async mounted(): Promise<void> {
    this.showDraftsSelected = false;
    this.showExpiredSelected = false;
    if (this.permissions?.superAdminAccess) {
      await this.loadCompanies();
    } else {
      this.loadCompanyTags({ companyId: this.user?.companyId });
    }
    setTimeout(() => {
      this.allowShowOptions = true;
    }, 250);
  },
  watch: {
    showDrafts: {
      handler() {
        this.showDraftsSelected = this.showDrafts;
        this.showToggle = false;
        setTimeout(() => {
          this.showToggle = true;
        }, 0);
        this.refreshData({ startStr: this.previousStart, endStr: this.previousEnd }, true);
      },
    },
    showExpired: {
      handler() {
        this.showExpiredSelected = this.showExpired;
        this.showToggle = false;
        setTimeout(() => {
          this.showToggle = true;
        }, 0);
        this.refreshData({ startStr: this.previousStart, endStr: this.previousEnd }, true);
      },
    },
    companyOptions: {
      async handler() {
        if (this.companyOptions?.length === 0 && this.permissions?.superAdminAccess) {
          this.allowShowOptions = false;
          await this.loadCompanies();
          if (this.companyValue) {
            this.loadCompanyTags({ companyId: this.companyValue.value });
          }
          setTimeout(() => {
            this.allowShowOptions = true;
          }, 250);
        }
      },
    },
    refresh: {
      async handler() {
        this.refreshData({ startStr: this.previousStart, endStr: this.previousEnd }, true);
      },
    },
    permissions: {
      async handler() {
        if (this.permissions?.superAdminAccess) {
          await this.loadCompanies();
        }
      },
    },
  },
});
